<template>
  <v-container class="my-6">
     <v-row justify="center" class="py-4">
        <n-form ref="form1">
            <n-field v-model="ocId" :rules="[$v.required ]"  label="No.Orden" cols="4"></n-field>
            <n-field v-model="telefono" label="Telefono" :rules="[ $v.required ]" cols="4"></n-field>
            <n-field v-model="fecha" label='Fecha (YYYY-MM-DD HH:mm:ss)' placeholder='YYYY-MM-DD HH:mm:ss' :rules="[$v.required]" cols="4"></n-field>
            <!-- <n-date-picker v-model="fecha" label='Fecha' :rules="[ ]" cols="4"></n-date-picker> -->
        </n-form>
            <v-btn  color="primary" @click="() => validation()">
                Buscar
            </v-btn>
     </v-row>
     <v-expansion-panels  class="py-2"  v-model="panel" multiple focusable :disabled="disabled">
    <v-expansion-panel>
        <v-expansion-panel-header>
          <v-list-item dense>
                  <!-- <v-list-item-icon>
                    <v-icon color="primary">mdi-package-variant-closed</v-icon>
                  </v-list-item-icon> -->
                  <v-list-item-title>{{tblLgtOc.ocId != undefined ? 'Id: '+tblLgtOc.ocId:''}}</v-list-item-title>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-title>{{tblLgtOc.fechaOc != undefined ? 'fecha: '+tblLgtOc.fechaOc:''}}</v-list-item-title>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-title>{{tblLgtOc.total != undefined ? 'Total: $'+tblLgtOc.total:''}}</v-list-item-title>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-title>{{tblLgtOc.tblLgtEstatusOc != undefined ? 'Estatus: '+tblLgtOc.tblLgtEstatusOc.estatusOc:''}}</v-list-item-title>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-title>{{tblLgtOc.tblEntEntidad != undefined ? 'Entidad: '+tblLgtOc.tblEntEntidad.entidad:''}}</v-list-item-title>
                </v-list-item>
                 <v-list-item dense>
                  <v-list-item-title>{{tblLgtOc.direccion != undefined ? 'Dirección: '+tblLgtOc.direccion:''}}</v-list-item-title>
                </v-list-item>
        </v-expansion-panel-header>
    <v-expansion-panel-content eager>
        <v-row class="py-4">
            <n-form ref="form2">
                <n-field v-model="tblLgtOc.rfc" :rules="[$v.required,$v.rfc]" label="RFC" cols="6"></n-field>
                <n-field v-model="tblLgtOc.razonSocial" label="Nombre o razon social" :rules="[ $v.required ]" cols="6" :disabled="tblLgtOc.rfc == undefined || tblLgtOc.rfc == null || tblLgtOc.rfc == ''"></n-field>
                <n-field v-model="tblLgtOc.invoiceEmail" label="Correo electronico" :rules="[ $v.required, $v.email ]" cols="6" :disabled="tblLgtOc.rfc == undefined || tblLgtOc.rfc == null || tblLgtOc.rfc == ''"></n-field>
                <n-field v-model="tblLgtOc.cp" label="Codigo postal" :rules="[ $v.required ]" cols="6" :disabled="tblLgtOc.rfc == undefined || tblLgtOc.rfc == null || tblLgtOc.rfc == ''"></n-field>
                <n-choice v-model="tblLgtOc.regimenFiscalId" label="Regimen fiscal" source="api/v1/public/fct/regimenesfiscal" item-text="label" item-value="valor" :return-object="false" :rules="[ $v.required ]" cols="6" :disabled="tblLgtOc.rfc == undefined || tblLgtOc.rfc == null || tblLgtOc.rfc == ''"></n-choice>
                <n-choice v-model="tblLgtOc.usoCfdi" label="Uso CFDI" source="api/v1/public/fct/usoscfdi" item-text="label" item-value="valor" :return-object="false" :rules="[ $v.required ]" cols="6" :disabled="tblLgtOc.rfc == undefined || tblLgtOc.rfc == null || tblLgtOc.rfc == ''"></n-choice>
                <n-choice v-model="tblLgtOc.metodoPago" label="Metodo de Pago" source="api/v1/public/fct/metodospago" item-text="label" item-value="valor" :return-object="false" :rules="[ $v.required ]" cols="6" :disabled="tblLgtOc.rfc == undefined || tblLgtOc.rfc == null || tblLgtOc.rfc == ''"></n-choice>
            </n-form>

             <v-btn  
              color="primary"
              @click="() => billing()" 
              block>
                Facturar
            </v-btn>
        </v-row>
    
    </v-expansion-panel-content>
    </v-expansion-panel>
     </v-expansion-panels>
    
  </v-container>
</template>
<script>
import axios from 'axios';
export default {
    data(){
        return{
            tblLgtOc:{},
            panel:[],
            ocId:null,
            telefono:null,
            fecha:null,
            disabled:true
        }
    },
    methods:{
        validation(){
            this.$refs.form1.validate().then(()=>{
                axios.get(`api/v1/public/orders/${this.ocId}/${this.telefono}/${this.fecha}/${process.env.VUE_APP_ENTIDAD}`).then((res)=>{
                  if(res.data.success){
                    this.tblLgtOc = res.data.data.order
                    this.panel= [0]
                    this.disabled=false
                  }else{
                    this.$dialog({
                      title: "Error",
                      text: res.data.message,
                    });
                  }
                }).catch()
            })
        },
          billing(){
          this.$refs.form2.validate().then(()=>{
            axios.post(`api/v1/public/fct/processor/${process.env.VUE_APP_ENTIDAD}`,
            this.tblLgtOc).then((res)=>{
              console.log(res)
            }).catch()
          }).catch();
      },
    }
}
</script>
